<template>
  <b-overlay :show="loading || !user">
    <validation-observer ref="newUserForm" #default="{ invalid }">
      <b-form v-if="user" ref="form" @submit.prevent="updateUser">
        <b-row>
          <b-col cols="12" xl="6" md="6">
            <b-card no-body class="">

              <div v-if="canDeleteMFA || canResendAccountConfirmationEmail"
                   class="p-1 d-flex justify-content-end"
              >
                <b-dropdown variant="link" class="p-0" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content class="p-0">
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>

                  <b-dropdown-item v-if="canResendAccountConfirmationEmail" @click="onResendAccountConfirmationEmail">
                    <feather-icon class="" icon="EditIcon" />
                    <span class="align-middle ml-50">Resend Confirmation Email </span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="canDeleteMFA && user.mfa_conf" @click="onResetAdminUserMFA">
                    <feather-icon class="text-danger" icon="EditIcon" />
                    <span class="align-middle text-danger ml-50">Reset MFA</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <b-card-body class="pt-2">
                <b-form-group label="First Name" label-for="first_name">
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="user.first_name"
                      :state="errors.length > 0 ? false : null"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group label="Last Name" label-for="last_name">
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="user.last_name"
                      :state="errors.length > 0 ? false : null"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group label="User Policies" label-for="user_policies">
                  <validation-provider
                    #default="{ errors }"
                    name="User Policies"
                  >
                    <v-select
                      v-model="user.policies"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="policyOptions"
                      :reduce="(val) => val.value"
                      :clearable="true"
                      :close-on-select="false"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group label="User Roles" label-for="user_roles">
                  <validation-provider
                    #default="{ errors }"
                    name="User Roles"
                  >
                    <v-select
                      v-model="user.user_roles"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userRoleOptions"
                      :reduce="(val) => val.value"
                      :clearable="true"
                      :close-on-select="false"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group label="User Status" label-for="status">
                  <validation-provider
                    #default="{ errors }"
                    name="User Status"
                    rules="required"
                  >
                    <v-select
                      v-model="user.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="(val) => val.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group label="Email" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                    vid="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="user.email"
                      :state="errors.length > 0 ? false : null"
                      name="email"
                      placeholder="john@example.com"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group label="Phone" label-for="phone">
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    rules="required"
                    vid="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="user.phone"
                      :state="errors.length > 0 ? false : null"
                      name="register-phone"
                      placeholder="0201234567"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-button
                class="mb-5 mx-2"
                style="width: 150px"
                variant="primary"
                block
                type="submit"
                :disabled="invalid || loading"
              >
                Update User
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BButton,
  BFormGroup,
  BForm,
  BFormInput,
} from "bootstrap-vue";

import { required, email } from "@validations";
import { get, cloneDeep } from "lodash";

import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const rawUserData = {
  first_name: "",
  last_name: "",
  permissions: "",
  email: "",
  phone: "",
};

export default {
  components: {
    vSelect,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BForm,
    BFormInput,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      user: null,
      userTypeOptions: [],
      policyOptions: [],
      userRoleOptions: [],
      statusOptions: [
        { label: "Active", value: "active" },
        { label: "In-Active", value: "inactive" },
        { label: "Banned", value: "banned" },
        { label: "Blocked", value: "blocked" },
        { label: "Pending Email Verification", value: "pending_confirmation" },
        { label: "Pending Phone Verification", value: "pending_sms" },
      ],

      // validation rules
      required,
      email,
    };
  },
  computed: {
    canResendAccountConfirmationEmail() {
      return this.can("users:CanResendAccountConfirmationEmail");
    },
    canDeleteMFA() {
      return this.can("mfa_configuration:CanDelete");
    },
  },
  created() {
    this.fetchUserDetails();
    this.fetchPermissions();
    this.fetchAdminRoles();
  },
  methods: {
    async fetchUserDetails() {
      try {
        this.loading = true;
        const { id } = this.$route.params;

        const response = await this.useJwt().adminService.fetchAdmin(id);
        this.user = get(response, "data.data");

        const policies = [];
        const roles = [];

        this.user.permissions.forEach((permission) => {
          if (permission.permission_type === "policy") {
            policies.push(permission.permission_id);
          } else {
            roles.push(permission.permission_id);
          }
        });

        console.log({
          policies,
          roles,
        });

        this.user.policies = policies;
        this.user.user_roles = roles;
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchPermissions() {
      try {
        this.loading = true;

        const query = {
          limit: 1000,
          page: 1,
        };

        const response = await this.useJwt().adminService.fetchAdminPolicies(
          query
        );
        this.policyOptions = get(response, "data.data", []).map((pm) => ({
          ...pm,
          label: pm.name,
          value: pm._id,
        }));
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchAdminRoles() {
      try {
        this.loading = true;

        const query = {
          limit: 1000,
          page: 1,
        };

        const response = await this.useJwt().adminService.getUserRoles(query);
        this.userRoleOptions = get(response, "data.data", []).map((pm) => ({
          ...pm,
          label: pm.title,
          value: pm._id,
        }));
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async updateUser() {
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.newUserForm.validate();
        if (!success) {
          return;
        }

        const policies = this.user.policies.map((permission_id) => ({
          permission_id,
          permission_type: "policy",
        }));
        const roles = this.user.user_roles.map((permission_id) => ({
          permission_id,
          permission_type: "role",
        }));
        await this.useJwt().adminService.updateAdmin(this.user._id, {
          permissions: [...policies, ...roles],
          status: this.user.status,
        });
        this.resetForm();

        await this.$router.push({
          name: "admin",
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Succcess",
            icon: "EditIcon",
            variant: "success",
            text: `User updated successfully`,
          },
        });
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onResendAccountConfirmationEmail() {
      try {
        this.loading = true;
        const result = await this.$swal({
          title: 'Resend Account Confirmation Email',
          text: "Are you sure you want to send another account confirmation email to user?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.value || !this.user._id) {
          return;
        }
        await this.useJwt().adminService.resendAccountConfirmationEmail(this.user._id);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "An account confirmation link shall be sent to the user."
          }
        });
        this.$router.push({
          name: "admin-users"
        })
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onResetAdminUserMFA() {
      try {
        this.loading = true;
        const result = await this.$swal({
          title: 'Reset Admin User MFA Config',
          text: "Are you sure you want to reset admin user mfa configuration ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.value || !this.user._id) {
          return;
        }
        await this.useJwt().adminService.resetAdminUserMFA(this.user._id);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "MFA on Admin User account has been reset successfully."
          }
        });
        await this.$router.push({
          name: "admin"
        })
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },

    resetForm() {
      Object.keys(cloneDeep(this.user)).forEach((key) => {
        const resetValue = get(rawUserData, key);
        this.user[key] = resetValue;
        this.$set(this.user, resetValue);
      });
    },
  },
};
</script>
