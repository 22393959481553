var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading || !_vm.user}},[_c('validation-observer',{ref:"newUserForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.user)?_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.updateUser.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6"}},[_c('b-card',{attrs:{"no-body":""}},[(_vm.canDeleteMFA || _vm.canResendAccountConfirmationEmail)?_c('div',{staticClass:"p-1 d-flex justify-content-end"},[_c('b-dropdown',{staticClass:"p-0",attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.canResendAccountConfirmationEmail)?_c('b-dropdown-item',{on:{"click":_vm.onResendAccountConfirmationEmail}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Resend Confirmation Email ")])],1):_vm._e(),(_vm.canDeleteMFA && _vm.user.mfa_conf)?_c('b-dropdown-item',{on:{"click":_vm.onResetAdminUserMFA}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle text-danger ml-50"},[_vm._v("Reset MFA")])],1):_vm._e()],1)],1):_vm._e(),_c('b-card-body',{staticClass:"pt-2"},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"User Policies","label-for":"user_policies"}},[_c('validation-provider',{attrs:{"name":"User Policies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.policyOptions,"reduce":function (val) { return val.value; },"clearable":true,"close-on-select":false,"multiple":""},model:{value:(_vm.user.policies),callback:function ($$v) {_vm.$set(_vm.user, "policies", $$v)},expression:"user.policies"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"User Roles","label-for":"user_roles"}},[_c('validation-provider',{attrs:{"name":"User Roles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.userRoleOptions,"reduce":function (val) { return val.value; },"clearable":true,"close-on-select":false,"multiple":""},model:{value:(_vm.user.user_roles),callback:function ($$v) {_vm.$set(_vm.user, "user_roles", $$v)},expression:"user.user_roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"User Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"User Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.user.status),callback:function ($$v) {_vm.$set(_vm.user, "status", $$v)},expression:"user.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email","placeholder":"john@example.com","disabled":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false : null,"name":"register-phone","placeholder":"0201234567","disabled":""},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-button',{staticClass:"mb-5 mx-2",staticStyle:{"width":"150px"},attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.loading}},[_vm._v(" Update User ")])],1)],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }